import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';

import { QrSignInComponent } from './components/qr-sign-in/qr-sign-in.component';
import { AppComponent } from './components/app/app.component';
import { SiteSignInComponent } from './components/site-sign-in/site-sign-in.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { ClosedownIframeComponent } from './components/closedown-iframe/closedown-iframe.component';
import { LoginUserComponent } from './components/login-user/login-user.component';
import { LoginPasswordComponent } from './components/login-password/login-password.component';
import { SiteSignOutComponent } from './components/site-sign-out/site-sign-out.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';

import { SafePipe } from './pipes/safe.pipe';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppMaterial } from './app.material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RECAPTCHA_SETTINGS, RecaptchaSettings, RecaptchaModule } from 'ng-recaptcha';
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { InternationalizationModule } from './components/internationalization/internationalization.module';
import { CityHttpInterceptor } from './cores/http-interceptor';
import { LoaderComponent } from './components/loader/loader.component';
import { InductionComponent } from './components/induction/induction.component';
import { QuestionrenderComponent } from './components/questionrender/questionrender.component';
import { InformationComponent } from './components/questionrender/information/information.component';
import { RadiobuttonComponent } from './components/questionrender/radiobutton/radiobutton.component';
import { AuthGuard } from './services/auth/auth.guard';
import { MultiplechoiceComponent } from './components/questionrender/multiplechoice/multiplechoice.component';
import { environment } from 'src/environments/environment';
import { SignedInVisitorComponent } from './components/signed-in-visitor/signed-in-visitor.component';
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { SiteComplianceCheckComponent } from './components/site-compliance-check/site-compliance-check.component';
import { UserDocumentsComponent } from './components/user-documents/user-documents.component';
import { ReasonForVisitComponent } from './components/reason-for-visit/reason-for-visit.component';
import { SiteInductionCompletedComponent } from './components/site-induction-completed/site-induction-completed.component';
import { SiteGuard } from './services/site/site.guard';
import { SignatureComponent } from './components/questionrender/signature/signature.component';
import { LabelComponent } from './components/questionrender/label/label.component';
import { CityonsiteAttachmentsComponent } from './components/questionrender/cityonsite-attachments/cityonsite-attachments.component';
import { CityonsiteprofileComponent } from './components/questionrender/cityonsiteprofile/cityonsiteprofile.component';
import { CityonsiteAttachmentsAddFileComponent } from './components/questionrender/cityonsite-attachments/cityonsite-attachments-add-file/cityonsite-attachments-add-file.component';
import { CityonsiteAttachmentExternalLinkComponent } from './components/questionrender/cityonsite-attachments/cityonsite-attachment-external-link/cityonsite-attachment-external-link.component';
import { CityonsiteAttachmentFromProfileComponent } from './components/questionrender/cityonsite-attachments/cityonsite-attachment-from-profile/cityonsite-attachment-from-profile.component';
import { TextComponent } from './components/questionrender/text/text.component';
import { JwtModule } from "@auth0/angular-jwt";
import { RegisterStepOneComponent } from './components/register-step-one/register-step-one.component';
import { Register2Component } from './components/register2/register2.component';
import { Register3Component } from './components/register3/register3.component';
import { SiteInductionAddEditComponent } from './components/site-induction-add-edit/site-induction-add-edit.component';
import { SiteInductionDeleteComponent } from './components/site-induction-delete/site-induction-delete.component';
import { RapidGlobalAddEditComponent } from './components/rapid-global-add-edit/rapid-global-add-edit.component';
import { RapidGlobalDeleteComponent } from './components/rapid-global-delete/rapid-global-delete.component';
import { ClosedownComponent } from './components/closedown/closedown.component';
import { UserDocumentsListComponent } from './components/user-documents-list/user-documents-list.component';
import { UserDocumentsAddComponent } from './components/user-documents-add/user-documents-add.component';
import { UserDocumentsDeleteComponent } from './components/user-documents-delete/user-documents-delete.component';
import { UserManagementListComponent } from './components/user-management-list/user-management-list.component';
import { UserManagementAddEditComponent } from './components/user-management-add-edit/user-management-add-edit.component';
import { SiteSignOutVisitSummaryComponent } from './components/site-sign-out-visit-summary/site-sign-out-visit-summary.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { LogoutComponent } from './components/logout/logout.component';
import { DateAgoPipe } from './pipes/date-ago-pipe';
import { RedirectComponent } from './components/redirect/redirect.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ErrorHandlerService } from './cores/error-handler.service';
import { UserProfileDeleteComponent } from './components/user-profile-delete/user-profile-delete.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { InfiniteScrollDirective, InfiniteScrollModule } from "ngx-infinite-scroll";
import { ReplacePlaceholderPipe } from './pipes/replace-placeholder.pipe';
import { VisitorTypeManagementComponent } from './components/visitor-type-management/visitor-type-management.component';
import { VisitorTypeManagementDeleteComponent } from './components/visitor-type-management-delete/visitor-type-management-delete.component';
import { SiteManagementComponent } from './components/site-management/site-management.component';
import { AdminManagementListComponent } from './components/admin-management-list/admin-management-list.component';
import { AdminManagementAddEditComponent } from './components/admin-management-add-edit/admin-management-add-edit.component';
import { SiteManagementEditComponent } from './components/site-management-edit/site-management-edit.component';
import { QRCodeModule } from 'angularx-qrcode';
import { VisitorTypeManagementAddEditComponent } from './components/visitor-type-management-add-edit/visitor-type-management-add-edit.component';
import { ReasonForVisitAddEditComponent } from './components/visitor-type-management-add-edit/reason-for-visit-add-edit/reason-for-visit-add-edit.component';
import { ReasonForVisitDeleteComponent } from './components/visitor-type-management-add-edit/reason-for-visit-delete/reason-for-visit-delete.component';
import { PermitQuestionRenderComponent } from './components/permit-question-render/permit-question-render.component';
import { PermitSignatureComponent } from './components/permit-question-render/permit-signature/permit-signature.component';
import { PermitMultipleChoiceComponent } from './components/permit-question-render/permit-multiple-choice/permit-multiple-choice.component';
import { PermitGroupComponent } from './components/permit-question-render/permit-group/permit-group.component';
import { PermitLabelComponent } from './components/permit-question-render/permit-label/permit-label.component';
import { OpenPermitComponent } from './components/open-permit/open-permit.component';
import { PermitTextBoxComponent } from './components/permit-question-render/permit-text-box/permit-text-box.component';
import { PermitSeparatorComponent } from './components/permit-question-render/permit-separator/permit-separator.component';
import { PermitRadioButtonComponent } from './components/permit-question-render/permit-radiobutton/permit-radiobutton.component';
import { PermitWelfareChecksComponent } from './components/permit-welfare-checks/permit-welfare-checks.component';
import { ClosePermitComponent } from './components/close-permit/close-permit.component';
import { PermitCurrentdateComponent } from './components/permit-question-render/permit-currentdate/permit-currentdate.component';
import { PermitCurrenttimeComponent } from './components/permit-question-render/permit-currenttime/permit-currenttime.component';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';

/**
 * The http loader factory
 * @param {HttpClient} http
 * @returns {TranslateHttpLoader}
 * @constructor
 */
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/locales/', `.json?v=${new Date().getTime()}`);
}

export function tokenGetter() {
  return localStorage.getItem("access_token");
}

// Please add all modules from Angular Material to app.material.ts file.
// Please reserve this file to import general modules to make it more cleaner and avoid redundancy

@NgModule({ declarations: [
        AppComponent,
        QrSignInComponent,
        SiteSignInComponent,
        SignInComponent,
        SafePipe,
        ClosedownIframeComponent,
        LoginUserComponent,
        LoginPasswordComponent,
        LoaderComponent,
        InductionComponent,
        SiteSignOutComponent,
        QuestionrenderComponent,
        InformationComponent,
        RadiobuttonComponent,
        MultiplechoiceComponent,
        SignedInVisitorComponent,
        UserProfileComponent,
        SiteComplianceCheckComponent,
        SignedInVisitorComponent,
        UserDocumentsComponent,
        ReasonForVisitComponent,
        SiteInductionCompletedComponent,
        SignatureComponent,
        LabelComponent,
        CityonsiteAttachmentsComponent,
        CityonsiteprofileComponent,
        CityonsiteAttachmentsAddFileComponent,
        CityonsiteAttachmentExternalLinkComponent,
        CityonsiteAttachmentFromProfileComponent,
        TextComponent,
        RegisterStepOneComponent,
        Register2Component,
        Register3Component,
        SiteInductionAddEditComponent,
        SiteInductionDeleteComponent,
        RapidGlobalAddEditComponent,
        RapidGlobalDeleteComponent,
        UserDocumentsListComponent,
        UserDocumentsAddComponent,
        UserDocumentsDeleteComponent,
        ClosedownComponent,
        UserManagementListComponent,
        UserManagementAddEditComponent,
        SiteSignOutVisitSummaryComponent,
        DialogComponent,
        LogoutComponent,
        DateAgoPipe,
        RedirectComponent,
        UserProfileDeleteComponent,
        WelcomeComponent,
        ReplacePlaceholderPipe,
        VisitorTypeManagementComponent,
        VisitorTypeManagementDeleteComponent,
        SiteManagementComponent,
        AdminManagementListComponent,
        AdminManagementAddEditComponent,
        SiteManagementEditComponent,
        VisitorTypeManagementAddEditComponent,
        ReasonForVisitAddEditComponent,
        ReasonForVisitDeleteComponent,
        PermitQuestionRenderComponent,
        PermitSignatureComponent,
        PermitMultipleChoiceComponent,
        PermitGroupComponent,
        PermitLabelComponent,
        OpenPermitComponent,
        PermitTextBoxComponent,
        PermitSeparatorComponent,
        PermitRadioButtonComponent,
        PermitWelfareChecksComponent,
        ClosePermitComponent,
        PermitCurrentdateComponent,
        PermitCurrenttimeComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        FormsModule,
        FlexLayoutModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        AppMaterial,
        RecaptchaModule,
        InternationalizationModule.forRoot({ locale_id: environment.defaultLang }),
        NgxScannerQrcodeModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter
            },
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.isServiceWorkerEnabled,
        }),
        InfiniteScrollDirective,
        QRCodeModule,
        AngularSignaturePadModule
    ], 
    providers: [
        AuthGuard,
        SiteGuard,
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: { siteKey: "6LcWB_0hAAAAAFOTxPOa-ZGlfUqiS3vi003Z8Ghz" } as RecaptchaSettings,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CityHttpInterceptor,
            multi: true
        },
        DatePipe,
        Meta,
        { provide: ErrorHandler, useClass: ErrorHandlerService },
        provideHttpClient(withInterceptorsFromDi())
    ] 
})
export class AppModule { }

